import { lOGIN_BANNER } from "./ColumnConstants";
import  lOGIN_BANNER_IMAGE_2  from "../assets/Hayosha/grameseva_banner.jpg";
import  lOGIN_BANNER_IMAGE_3  from "../assets/Hayosha/grameseva_banner_2.jpg";
export const AUTH_TOKEN = "auth_token";
export const USER_DATA_TOKEN = "user_data_token";
export const USER_DATA = "user_data";


export const Login_CAROUSEL_DATA =[
    {
        src:lOGIN_BANNER,
        alt:"banner img",
        id:1
    },
    {
        src:lOGIN_BANNER_IMAGE_3,
        alt:"grameseva_banner_2",
        id:2
    },
    {
        src:lOGIN_BANNER_IMAGE_2,
        alt:"grameseva_banner",
        id:3
    }
];